<!-- 个人中心 -->
<template>
  <div class="personal-page">
    <img src="@/assets/logo.png" class="wechat-logo" />
    <p class="status">{{ status | formatStatus }}</p>
    <p class="tips" v-if="1 == status">
      已成功绑定微信，您可以使用微信扫码登录航都院OA系统，接收系统的消息和通知
    </p>
  </div>
</template>

<script>
import { accountWechatBind } from "@/api/account";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      status: "99", //2-"未绑定" 1-"已绑定"
    };
  },
  name: "bindWechat",
  components: {},
  computed: {},
  filters: {
    formatStatus(val) {
      const rules = {
        2: "未绑定",
        1: "已绑定",
      };
      return rules[val] || "";
    },
  },
  mounted() {
    let code = this.$route.query.code;
    // 微信授权后
    if (code) {
      this.bindWechat(code);
    }
  },

  methods: {
    bindWechat(code) {
      const postData = {
        code: code,
        unionCode: this.$route.params.id,
      };
      accountWechatBind(postData).then((res) => {
        this.status=1;
        localStorage.setItem("isBind", "isBind" ,{
          path: `/bindWechat/${this.$route.params.time}`,
        }); // isBind-已绑定
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  .wechat-logo {
    margin-top: 2rem;
    width: 4rem;
    height: 4rem;
  }
  .status {
    font-size: 0.4rem;
    color: #09bb07;
    height: 0.5rem;
    margin-top: 0.2rem;
  }
  .tips {
    color: #999;
    font-size: 0.26rem;
    margin-top: 1rem;
    max-width: 6.9rem;
  }
  .button {
    margin-top: 0.3rem;
    width: 6.9rem;
    height: 0.8rem;
    margin-bottom: 1rem;
    background: #0094EE;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.3rem;
  }
}
</style>
